import {useCallback, useEffect, useRef, useState} from 'react';
import {REDUCED_ATF_V2_VIDEO_HEIGHT_PERCENTAGE} from '@/components/organisms/Header';

enum SCROLL {
  UP = 'UP',
  DOWN = 'DOWN',
}

const calculateOffsetVideo = (headerHeight: number) => {
  const viewportHeight = window.innerHeight;
  return viewportHeight * (REDUCED_ATF_V2_VIDEO_HEIGHT_PERCENTAGE / 100) - headerHeight;
};

const useScrollDirection = (offset = 0) => {
  const scroll = useRef(0);
  const [scrollDirection, setScrollDirection] = useState(SCROLL.UP);
  const [offsetReached, setOffsetReached] = useState(false);
  const [videoOffsetReached, setVideoOffsetReached] = useState(false);

  const offsetVideo = calculateOffsetVideo(offset);

  const handleScroll = useCallback(() => {
    setScrollDirection(window.scrollY > scroll.current ? SCROLL.DOWN : SCROLL.UP);
    setOffsetReached(window.scrollY > offset);
    setVideoOffsetReached(window.scrollY > offsetVideo);
    scroll.current = window.scrollY;
  }, [offset, offsetVideo]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {direction: scrollDirection, offsetReached, videoOffsetReached};
};

export {useScrollDirection, SCROLL};
