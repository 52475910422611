import React, {useEffect, useState, useCallback} from 'react';
import {OnErrorFn, IntlErrorCode} from '@formatjs/intl';
import {IntlProvider} from 'react-intl';

import {getSiteConfig} from '@/modules/Site';
import {getI18nTranslations} from '../services';
import {LocaleDictionary, LocaleProject} from '../types/Locale';
import {isSeoRender} from '@/modules/SEO';

interface I18NProps {
  children: React.ReactNode;
  projects: LocaleProject[];
}

export const I18NProvider: React.FC<I18NProps> = ({children, projects}) => {
  const {locale} = getSiteConfig();
  const [messages, setMessages] = useState<LocaleDictionary | undefined>();

  useEffect(() => {
    if (!messages) {
      getI18nTranslations(locale, projects).then(setMessages);
    }
  }, [locale, projects, messages]);

  useEffect(() => {
    if (Object.keys(messages || {}).length && !isSeoRender) {
      const el = document.querySelector('html') as HTMLHtmlElement;
      el.classList.add('loaded');
    }
  }, [messages]);

  const onError: OnErrorFn = useCallback(err => {
    if (err.code === IntlErrorCode.MISSING_TRANSLATION) return;
    throw err;
  }, []);

  return (
    <IntlProvider onError={onError} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};
