import {http} from '@/utils/http';
import {LocaleDictionary} from '../types/Locale';
import {sendException} from '@/modules/Monitoring';
import {i18nTranslationsStatic} from '@/modules/I18N/services/i18nTranslationsStatic';

const cached: Record<string, LocaleDictionary> = {};

export const i18nTranslationsApi = async (locale: string, projects: string[]): Promise<LocaleDictionary> => {
  try {
    const i18nKey = [...projects].sort().join(',');

    if (cached[i18nKey]) {
      return cached[i18nKey] as LocaleDictionary;
    }

    const [language, country] = locale.split('-');
    const translationParams = projects.map(project => `projectFilter=${project}`).join('&');
    const dictionary = await http.get<LocaleDictionary>(
      `/api/v2/translations/${language}/${country}?${translationParams}`,
    );

    cached[i18nKey] = dictionary;

    return dictionary;
  } catch (e) {
    sendException(e as Error);
    return await i18nTranslationsStatic(locale);
  }
};
