import {useMemo} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';
import {useHomeVisited} from './useHomeVisited';

const {GRW_REDUCED_ATF} = experiments;

export interface ReducedATFResult {
  isLoading: boolean;
  control: boolean;
  v1: boolean;
  v2: boolean;
  variant: 'control' | 'v1' | 'v2';
}

export const useReducedATF = (): ReducedATFResult => {
  const isHomeVisited = useHomeVisited();
  const {isLoading, variant} = useMendel({name: GRW_REDUCED_ATF.name, enabled: isHomeVisited});

  const result = useMemo(() => {
    if (variant === GRW_REDUCED_ATF.variants.v1) return 'v1';
    if (variant === GRW_REDUCED_ATF.variants.v2) return 'v2';
    return 'control';
  }, [variant]);

  return useMemo(
    () => ({
      isLoading,
      control: result === 'control',
      v1: result === 'v1',
      v2: result === 'v2',
      variant: result,
    }),
    [isLoading, result],
  );
};
