import React, {useEffect, useMemo, useState} from 'react';
import {Aurora, useDevice} from '@lookiero/aurora';

import {PATH, Route, useHistory} from '@/routes';
import {Header, PanelContent} from '@/components/organisms';
import {eventManager, UI_EVENT} from '@/modules/EventManager';
import {isSeoRender, SEOManager} from '@/modules/SEO';
import {useIntl} from '@/modules/I18N';
import {SECTION} from '@/modules/Tracking';
import {HEADER_HEIGHT} from '@/components/organisms/Header';
import {useMendelContext} from '@/modules/ABTesting';
import {SCROLL, useScrollDirection} from './hooks/useScrollDirection';
import {LoadingLogo} from '@/components/primitives/Logo/LoadingLogo';
import {RouteProps} from 'react-router';

export interface BaseLayoutConfig {
  header: {
    visible: boolean;
    showMenu: boolean;
    showLogin: boolean;
  };
}

interface BaseLayoutProps extends RouteProps {
  component: React.FC<{pathTo?: string; isHeaderVisible: boolean}>;
  i18n: React.FC<{children: React.ReactNode}>;
  path?: string;
  config?: BaseLayoutConfig;
}

export const DEFAULT_LAYOUT_CONFIG: BaseLayoutConfig = {
  header: {
    visible: true,
    showMenu: true,
    showLogin: true,
  },
};

// hay que poner el BaseLayoutInner dentro porque usa el useIntl
export const BaseLayout: React.FC<BaseLayoutProps> = ({i18n, ...props}) => {
  const TranslationsProvider = i18n;

  return (
    <TranslationsProvider>
      <BaseLayoutInner {...props} />
    </TranslationsProvider>
  );
};

export const BaseLayoutInner: React.FC<Omit<BaseLayoutProps, 'i18n'>> = ({
  component: Component,
  path,
  config = DEFAULT_LAYOUT_CONFIG,
  ...props
}) => {
  const {screen} = useDevice();
  const {loadedMessages} = useIntl();
  const history = useHistory();
  const {direction, offsetReached} = useScrollDirection(HEADER_HEIGHT);

  const [headerVisible, setHeaderVisible] = useState(config.header.visible);
  const [panelVisible, setPanelVisible] = useState(false);

  const {freeTrial, previewOnHomePage, reducedATF} = useMendelContext();

  useEffect(() => {
    if (config.header.visible) {
      if (direction === SCROLL.DOWN && offsetReached && headerVisible) {
        setHeaderVisible(false);
      } else if (direction === SCROLL.UP && !headerVisible) {
        setHeaderVisible(true);
      }
    }
  }, [headerVisible, direction, offsetReached, config.header.visible]);

  useEffect(
    () =>
      history.listen(() => {
        if (panelVisible) setPanelVisible(false);
      }),
    [history, panelVisible],
  );

  useEffect(() => {
    if (panelVisible) {
      eventManager.emit(UI_EVENT.PAGE_VIEW, {section: SECTION.OPEN_MENU});
    }
  }, [panelVisible]);

  const isLoading = !loadedMessages || freeTrial.isLoading || previewOnHomePage.isLoading || reducedATF.isLoading;
  const className = isLoading ? '' : 'main-content';

  const marginTopMainContainer = useMemo(() => {
    if (!config.header.visible) return undefined;
    if (!screen.L && reducedATF.v2) {
      return undefined;
    }
    return HEADER_HEIGHT;
  }, [config.header.visible, screen.L, reducedATF.v2]);

  return (
    <Aurora>
      <LoadingLogo isVisible={!isLoading} />
      <div className={`viewport-height main ${className}`} style={{visibility: isLoading ? 'hidden' : 'visible'}}>
        <SEOManager path={path} />
        {config.header.visible && (
          <Header
            isVisible={headerVisible}
            openPanel={config.header.showMenu ? () => setPanelVisible(true) : undefined}
            showLoginLink={config.header.showLogin}
            onLogoPress={() => history.push(PATH.HOME)}
          />
        )}
        {!isSeoRender && <PanelContent isVisible={panelVisible} onClose={() => setPanelVisible(false)} />}
        <div style={{marginTop: marginTopMainContainer}} id={'main-container'}>
          <Route path={path} {...props}>
            <Component pathTo={path} isHeaderVisible={headerVisible} />
          </Route>
        </div>
      </div>
    </Aurora>
  );
};
