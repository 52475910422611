import React from 'react';

import {I18NProvider} from '../context/I18nContext';
import {LocaleProject} from '../types/Locale';

export const I18NGiftCardProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <I18NProvider projects={[LocaleProject.HOME_USER_SITE_FRONT, LocaleProject.GIFTCARDS]}>{children}</I18NProvider>
  );
};
