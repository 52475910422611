import React, {useMemo} from 'react';
import {ALIGN, ButtonIcon, COLOR, Header as AuroraHeader, Link, Motion, Theme, useDevice, View} from '@lookiero/aurora';
import {Logo, LogoVariant} from '@/components/primitives/Logo';
import {useScrollDirection} from '@/components/templates/BaseLayout/hooks/useScrollDirection';
import {useMendelContext} from '@/modules/ABTesting';
import {useIntl} from '@/modules/I18N';
import {PATH} from '@/routes';
import {styles} from './Header.styles';
import {useHeadless} from './hooks/useHeadless';
import {StyleSheet} from 'react-native';

export const HEADER_HEIGHT = Theme.get().space12;
export const REDUCED_ATF_V2_VIDEO_HEIGHT_PERCENTAGE = 65;

interface HeaderProps {
  isVisible: boolean;
  onLogoPress: () => void;
  openPanel?: () => void;
  showLoginLink: boolean;
}

export const Header: React.FC<HeaderProps> = ({isVisible, onLogoPress, openPanel, showLoginLink}) => {
  const {screen} = useDevice();
  const {headless} = useHeadless();
  const {formatMessage} = useIntl();
  const {reducedATF} = useMendelContext();
  const {videoOffsetReached} = useScrollDirection(HEADER_HEIGHT);

  const {linkColor, logoVariant, headerStyle} = useMemo(() => {
    const isDesktop = screen.L;
    const isDarkMode = reducedATF.v2 && !isDesktop && !videoOffsetReached;
    const linkColor = isDarkMode ? COLOR.ICON_ON_PRIMARY : COLOR.ICON;
    const logoVariant = isDarkMode ? LogoVariant.DARK : LogoVariant.LIGHT;

    const headerStyle = [
      styles.header,
      isDarkMode && styles.headerReducedATFExperimentV2,
      isDesktop &&
        !reducedATF.control && {
          height: HEADER_HEIGHT,
          justifyContent: 'center',
        },
    ];

    return {linkColor, logoVariant, headerStyle};
  }, [screen.L, reducedATF.v2, reducedATF.control, videoOffsetReached]);

  if (headless) return null;
  return (
    <Motion
      style={styles.container}
      value={{
        opacity: isVisible ? 1 : 0,
        top: isVisible ? '0px' : `${0 - HEADER_HEIGHT}px`,
      }}
      wide
    >
      {!screen.L && reducedATF.v2 && <View style={[StyleSheet.absoluteFill, styles.gradientTop]} />}
      <AuroraHeader
        style={headerStyle}
        logo={
          <View style={styles.logo}>
            <Link action level={3} onPress={onLogoPress}>
              <Logo variant={logoVariant} />
            </Link>
          </View>
        }
        onMenu={(!reducedATF.v2 || screen.L) && openPanel ? openPanel : undefined}
        title={' '} // Esto alinea el <ButtonIcon> de la V2. Si se acaba productivizando se puede quitar porque
        // usaríamos el icono del onMenu
      >
        {/* En caso de productivizar la V2 habría que hacer cambios en Aurora para que permita cambiar el color
         del icono del menú. Lo ideal sería plantearse no usar ni AuroraHeader ni Motion. */}
        {reducedATF.v2 && !screen.L && (
          <ButtonIcon
            alignSelf={ALIGN.CENTER}
            color={linkColor}
            name="menu"
            nativeID="menu-button"
            onPress={openPanel}
            testID="menu-button"
            small
          />
        )}
        {screen.L && showLoginLink && (
          <Link action align={ALIGN.CENTER} level={3} style={styles.login} href={PATH.LOGIN}>
            {formatMessage({id: 'home.login'})}
          </Link>
        )}
      </AuroraHeader>
    </Motion>
  );
};
