import {isSeoRender} from '@/modules/SEO';
import {SiteConfig, SITE_CONFIG} from './config';
import {Site} from '@/types/Site';
import {Country} from '@/types/Country';

const getSites = (): SiteConfig[] => Object.values(SITE_CONFIG);

const getLookieroURL = (domain: string) => `https://lookiero.${domain}`;

const hasSite = () => (window.location.hostname.split('.').pop() || '').toLowerCase() !== 'com';

const getCurrentSiteConfig = (): SiteConfig => {
  let value: SiteConfig | undefined;

  const sitesList = getSites();

  if (isSeoRender) {
    const domain: string = window.Lookiero.domain.toLowerCase();
    value = sitesList.find(site => site.domain.toLowerCase() === domain);
  } else {
    const hostname = window.location.hostname.toLowerCase();
    value = sitesList.find(site => hostname.endsWith(site.domain.toLowerCase()));
  }

  return value || SITE_CONFIG[Site.UK];
};

const CURRENT_SITE_CONFIG = getCurrentSiteConfig();
const getSiteConfig = () => CURRENT_SITE_CONFIG;

const getSiteByCountry = (country: Country) => {
  if (country === Country.LU) return SITE_CONFIG.BE;
  return getSites().find(site => site.country === country);
};

export {getSites, getSiteConfig, getLookieroURL, hasSite, getSiteByCountry};
