import StyleSheet from 'react-native-extended-stylesheet';
import {HEADER_HEIGHT} from '@/components/organisms/Header';

export const styles = StyleSheet.create({
  container: {
    zIndex: '$elevationLayerM',
    position: 'fixed',
  },
  header: {
    backgroundColor: 'white',
    zIndex: '$elevationLayerS',
  },
  headerReducedATFExperimentV2: {
    backgroundColor: 'transparent',
  },
  gradientTop: {
    justifyContent: 'flex-end',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%)',
  },
  login: {
    marginRight: '$space6',
  },
  logo: {
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
