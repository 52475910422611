import {isSeoRender} from '@/modules/SEO';
import {i18nTranslationsApi} from './i18nTranslationsApi';
import {i18nTranslationsStatic} from './i18nTranslationsStatic';
import {LocaleDictionary} from '../types/Locale';

export const getI18nTranslations = (locale: string, projects: string[]): Promise<LocaleDictionary> => {
  if (isSeoRender) {
    return i18nTranslationsStatic(locale);
  }

  return i18nTranslationsApi(locale, projects);
};
