import React, {useContext, useMemo} from 'react';

import {FreeTrialResult, useFreeTrial} from '@/pages/Home/hooks/useFreeTrial';
import {PreviewOnHomepageResult, usePreviewOnHomePage} from '@/pages/Home/hooks/usePreviewOnHomePage';
import {ReducedATFResult, useReducedATF} from '@/pages/Home/hooks/useReducedATF';

interface MendelContextValue {
  freeTrial: FreeTrialResult;
  previewOnHomePage: PreviewOnHomepageResult;
  reducedATF: ReducedATFResult;
}

const initialValue: MendelContextValue = {
  freeTrial: {
    isLoading: false,
    isExpEnabled: false,
    hasFreeTrial: false,
  },
  previewOnHomePage: {
    isLoading: false,
    isEnabled: false,
  },
  reducedATF: {
    isLoading: false,
    control: true,
    v1: false,
    v2: false,
    variant: 'control',
  },
};

const MendelContext = React.createContext<MendelContextValue>(initialValue);

const MendelProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const freeTrialResult = useFreeTrial();
  const previewOnHomePageResult = usePreviewOnHomePage();
  const reducedATFResult = useReducedATF();

  const value = useMemo(
    () => ({
      freeTrial: freeTrialResult,
      previewOnHomePage: previewOnHomePageResult,
      reducedATF: reducedATFResult,
    }),
    [freeTrialResult, previewOnHomePageResult, reducedATFResult],
  );

  return <MendelContext.Provider value={value}>{children}</MendelContext.Provider>;
};

const useMendelContext = () => {
  const context = useContext(MendelContext);

  if (context === undefined) {
    throw new Error('useMendelContext must be used within MendelProvider');
  }

  return context;
};

export {useMendelContext, MendelProvider};
