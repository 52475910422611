import {useMemo} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';
import {useHomeVisited} from './useHomeVisited';

const {GRW_PREVIEW_ON_HOMEPAGE} = experiments;

export interface PreviewOnHomepageResult {
  isLoading: boolean;
  isEnabled: boolean;
  bullets?: {defaultMessage: string; id: string}[];
}

export const usePreviewOnHomePage = (): PreviewOnHomepageResult => {
  const isHomeVisited = useHomeVisited();

  const {isLoading, variant} = useMendel({name: GRW_PREVIEW_ON_HOMEPAGE.name, enabled: isHomeVisited});

  const isEnabled = variant === GRW_PREVIEW_ON_HOMEPAGE.variants.v1;

  return useMemo(() => {
    if (!isEnabled) return {isLoading, isEnabled};

    return {
      isLoading,
      isEnabled,
      bullets: [
        {
          defaultMessage: 'Your Personal Shopper prepares a unique selection of pieces for you',
          id: 'exp_previewonhomepage_home.main.bullet1',
        },
        {
          defaultMessage: "Get a preview and confirm which ones you'd like to receive",
          id: 'exp_previewonhomepage_home.main.bullet2',
        },
        {
          defaultMessage: 'Try them on, pay for the items you like and return the rest for free',
          id: 'exp_previewonhomepage_home.main.bullet3',
        },
      ],
    };
  }, [isLoading, isEnabled]);
};
