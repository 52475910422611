export const experiments = {
  GRW_FREE_TRIAL_OUT_V2: {
    name: 'grw_freeTrialOutV2',
    variants: {
      control: 'grw_freeTrialOutV2_control',
      v1: 'grw_freeTrialOutV2_v1',
    },
  },
  GRW_FREE_TRIAL_OUT_AFTERMATH: {
    name: 'grw_freeTrialOut_Aftermath',
    variants: {
      control: 'grw_freeTrialOut_Aftermath_control',
      v1: 'grw_freeTrialOut_Aftermath_v1',
    },
  },
  GRW_PRODUCT_SHOWCASE: {
    name: 'grw_productShowcase',
    variants: {
      control: 'grw_productShowcase_control',
      v1: 'grw_productShowcase_v1',
      v2: 'grw_productShowcase_v2',
    },
  },
  GRW_Q1SWAP: {
    name: 'grw_Q1Swap',
    variants: {
      control: 'grw_Q1Swap_control',
      v1: 'grw_Q1Swap_v1',
    },
  },
  GRW_PAIN_SLIDER: {
    name: 'grw_PainSlider',
    variants: {
      control: 'grw_PainSlider_control',
      v1: 'grw_PainSlider_v1',
    },
  },
  GRW_PREVIEW_ON_HOMEPAGE: {
    name: 'grw_PreviewOnHomepage',
    variants: {
      control: 'grw_PreviewOnHomepage_control',
      v1: 'grw_PreviewOnHomepage_v1',
    },
  },
  GRW_REDUCED_ATF: {
    name: 'grw_ReducedATF',
    variants: {
      control: 'grw_ReducedATF_control',
      v1: 'grw_ReducedATF_v1',
      v2: 'grw_ReducedATF_v2',
    },
  },
} as const;
