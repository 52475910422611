import React, {Suspense} from 'react';
import {View} from '@lookiero/aurora';

import {Route, Router, Switch} from './bridge';
import {BaseLayout, BaseLayoutConfig} from '@/components/templates';
import {hasSite} from '@/modules/Site';
import {I18NUsfProvider, I18NHomeProvider, I18NGiftCardProvider} from '@/modules/I18N';
import {
  Home,
  HomeSiteCom,
  Legal,
  NotFound,
  Unsubscribe,
  Sustainability,
  Faq,
  GiftCardLanding,
  GiftCardDigital,
  GiftCardPrint,
  ReferralsLanding,
  GiftCardThankYou,
} from './Router.components';
import {RouterEvents} from './components/RouterEvents';
import {PATH} from './Router.config';

const GC_HEADER_CONFIG = {
  visible: true,
  showLogin: false,
  showMenu: false,
};

const GC_LANDING_CONFIG: BaseLayoutConfig = {
  header: GC_HEADER_CONFIG,
};

const GC_FORM_CONFIG: BaseLayoutConfig = {
  header: {
    ...GC_HEADER_CONFIG,
    visible: false,
  },
};

const AppRouter = () => (
  <Router basename="/">
    <RouterEvents />
    <Suspense fallback={<View />}>
      {hasSite() ? (
        <Switch>
          <BaseLayout exact path={PATH.HOME} component={Home} i18n={I18NHomeProvider} />
          <Route path={PATH.FAQ_OLD} component={Faq} />
          <BaseLayout exact path={PATH.LEGAL} component={Legal} i18n={I18NUsfProvider} />
          <BaseLayout exact path={PATH.SUSTAINABILITY} component={Sustainability} i18n={I18NUsfProvider} />
          <BaseLayout path={PATH.UNSUBSCRIBE} component={Unsubscribe} i18n={I18NUsfProvider} />
          <BaseLayout
            exact
            path={PATH.GIFT_CARD}
            component={GiftCardLanding}
            config={GC_LANDING_CONFIG}
            i18n={I18NGiftCardProvider}
          />
          <BaseLayout
            exact
            path={PATH.GIFT_CARD_DIGITAL}
            component={GiftCardDigital}
            config={GC_FORM_CONFIG}
            i18n={I18NGiftCardProvider}
          />
          <BaseLayout
            exact
            path={PATH.GIFT_CARD_PRINT}
            component={GiftCardPrint}
            config={GC_FORM_CONFIG}
            i18n={I18NGiftCardProvider}
          />
          <BaseLayout exact path={PATH.REFERRALS_LANDING} component={ReferralsLanding} i18n={I18NUsfProvider} />
          <Route exact path={PATH.INDEX} component={HomeSiteCom} />
          <Route exact path={PATH.GIFT_CARD_THANK_YOU} component={GiftCardThankYou} />
          <BaseLayout component={NotFound} i18n={I18NUsfProvider} />
        </Switch>
      ) : (
        <Switch>
          <Route exact component={HomeSiteCom} />
        </Switch>
      )}
    </Suspense>
  </Router>
);

export {AppRouter};
